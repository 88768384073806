import { GlobeAltIcon } from "@heroicons/react/20/solid";
import React from "react";

interface Testimonial {
  name: string;
  jobTitle: string;
  url?: string;
  profileImage?: string;
  text: string;
  date: string;
  tweetUrl?: string;
}

const testimonials: Testimonial[] = [
  {
    name: "Gergely Orosz",
    jobTitle: "The Pragmatic Engineer",
    tweetUrl: "https://twitter.com/GergelyOrosz/status/1823610797513171160",
    text: `Recently came across a neat site called NextLevelJobs.eu: which collects dev openings at "top tier" companies in the EU that offer top of the market (I'd call these Tier 2/3 in the trimodal model).
    \n

It crawls these jobs at those companies and surfaces new listings.`,
    profileImage: "/gergely.png",
    date: "2024/08",
  },
  {
    name: "Luis Carlos Antunes",
    url: "https://lcantunes.com/",
    jobTitle: "Junior Software Developer",
    text: "I love how clean and straightforward nextleveljobs.eu is. It's a breath of fresh air compared to other job boards that are cluttered and overwhelming. Focusing specifically on tech positions is a huge plus for me, and the filters are incredibly helpful. I can find the perfect job in seconds. Plus, the team behind it is always open to feedback, which shows they truly care about the user experience.",
    date: "2024/09",
    profileImage: "/profile_luis.png",
  },
  {
    name: "Dejan B.",
    jobTitle: "Senior Software Engineer",
    text: "I take a look at nextleveljobs.eu every once in a while, and I like how simple and straightforward it is.",
    date: "2024/09",
  },
];

const TestimonialTile: React.FC<Testimonial> = ({
  name,
  jobTitle,
  text,
  date,
  url,
  profileImage,
  tweetUrl,
}) => (
  <div className="bg-slate-800 h-fit shadow-lg rounded-lg p-6 m-4 border border-lime-800">
    <div className="flex gap-2">
      {profileImage && (
        <img
          src={profileImage}
          alt={`${name}'s profile`}
          className="w-12 h-12 rounded-full mr-4"
        />
      )}
      <div>
        <h3 className="font-bold text-lg mb-1 text-gray-100">{name}</h3>
        <h4 className="font-semibold text-sm mb-2 text-lime-300">{jobTitle}</h4>
      </div>
    </div>
    <p className="text-gray-200 mb-4">
      {text.split("\n").map((line, index) => (
        <span key={index} className="block mt-2">
          {line}
        </span>
      ))}
    </p>
    <p className="text-sm text-gray-400 flex gap-2">
      <span className="my-auto">{date}</span>
      {url && (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-lime-700 transition-colors duration-300"
        >
          <GlobeAltIcon className="h-6 w-6 inline" />
        </a>
      )}
      {tweetUrl && (
        <a
          href={tweetUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-lime-700 transition-colors duration-300 my-auto"
        >
          <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        </a>
      )}
    </p>
  </div>
);

export const Testimonials: React.FC = () => {
  return (
    <div className="container mx-auto py-8">
      <h2 className="text-3xl font-bold text-center mb-8">
        What People Are Saying
      </h2>
      <div className="flex flex-col gap-6">
        {testimonials.map((testimonial, index) => (
          <div className="md:basis-1/3 flex-grow-0" key={index}>
            <TestimonialTile key={index} {...testimonial} />
          </div>
        ))}
      </div>
    </div>
  );
};
